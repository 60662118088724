@font-face {
    font-family: 'montserratregular';
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
