.planos__interna__header{
	max-width: 600px;
	width: 100%;
	margin-bottom: 10px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}
.planos__interna__desc{
	max-width: 840px;
	width: 100%;
	margin-bottom: 30px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}
.plano__card__interna{
	display: block;
	padding: 35px 20px;
	background-color: #EEEEEE;
	color: #000;
	border: none;
	margin-bottom: 20px;

	&:focus{
		outline: none;
	}
}
.planos__card__img{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	height: 75px;
	border-radius: 50%;
	background-color: #fff;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}
.planos__titulo{
	font-family: 'montserratbold';
	font-size: 25px;
	line-height: 29px;
	color: #021531;
	margin-bottom: 10px;
	text-align: center;
}
.planos__card__listagem{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 15px;
}
.planos__card__item{
	display: flex;
	justify-content: flex-start;
	text-align: left;
	font-size: 12px;
	line-height: 16px;
	align-items: center;
	margin-bottom: 5px;

	lazy-image{
		margin-right: 5px;
	}
}
.planos__card__numero{
	font-size: 30px;
	line-height: 34px;
	text-align: center;
	color: #021531;
	font-family: 'montserratbold';
}
.planos__card__mes{
	font-size: 13px;
	line-height: 17px;
	color: #021531;
}
.planos__card__valor{
	margin-bottom: 20px;
}
.planos__card__btn{
	width: 196px;
	height: 56px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 56px;
	background-color: #ff5500;
	color: #000;
	border: none;
	transition: all 0.3s linear;

	&:focus{
		outline: none;
	}

	&:hover{
		text-decoration: none;
		color: #fff;
	}
}
.modal__planos{
	.modal-dialog{
		max-width: 800px;
		width: 90%;
	}
	.modal-header{
		display: flex;
		justify-content: flex-end;
	}
}
.modal__close{
	border: none;
	background-color: transparent;
	color: #000;
	padding: 0px;
	font-size: 20px;
	line-height: 20px;

	&:focus{
		outline: none;
	}
}
.planos__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 15px 15px 13px 15px;
	color: #000;
	background-color: #EEEEEE;
	border: none;
	font-family: 'montserratbold';

	&::placeholder{
		color: #000;
	}
	&:focus{
		outline: none;
	}
}
.form__planos__btn{
	margin-bottom: 10px;
	padding-top: 10px;
}

.box__mapa{
	padding: 50px;
}
