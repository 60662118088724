@import "_home.scss";
@import "_flytech.scss";
@import "_planos.scss";
@import "_suporte.scss";
@import "_contato.scss";
@import "_condominio.scss";
.login__interna{
	padding: 100px 0px;
}
.login__titulo{

	h1 {
		font-size: 20px;
		line-height: 24px;
		color: #333333;
		margin-bottom: 30px;
		text-align: center;
		font-family: montserratbold;
	}
}
.login__form{
	max-width: 460px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.btn__custom{
	min-width: 250px;
	height: 50px;
	border: 2px solid #ff5500;
	color: #ff5500;
	font-family: montserratbold;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	background-color: transparent;
	transition: all 0.3s linear;

	&:focus{
		outline: none;
	}

	&:hover{
		background-color: #ff5500;
		color: #fff;
		text-decoration: none;
	}
}
.login__input{
	border: 1px solid #d1d1d1;
	height: 50px;
	width: 100%;
	display: block;
	border-radius: 25px;
	color: #1f1634;
	padding: 13px 26px;
	&::placeholder{
		color: #1f1634;
	}
}
.titulo__area{
	font-family: montserratregular;
	font-size: 50px;
	line-height: 54px;
	text-align: center;
	margin-bottom: 20px;

	span{
		color: #ff5500;
		font-family: montserratbold;
	}
}
.area__sub{
	text-align: center;
	font-family: montserratbold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 30px;
}
.restrita__interna{
	padding: 100px 0px;
}
.area__grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > *{
		max-width: 200px;
		width: 100%;
		display: block;
		margin: 5px;
		background-color: #ededed;
		padding-bottom: 30px;
		text-align: center;
		position: relative;
		min-height: 205px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 30px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #1f1634;
			color: #fff;
			text-decoration: none;

			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}

		&:after,
		&:before{
			content: '';
			position: absolute;
			height: 3px;
			background-color: #ff5500;
			left: 0px;
			width: 100%;
			transition: all 0.3s linear;
		}
		&:after{
			top: 0px;
		}
		&:before{
			bottom: 0px;
			opacity: 0;
		}

		.area__titulo{
			font-family: montserratbold;
			font-size: 21px;
			line-height: 24px;
			min-height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.area__icone{
			margin-bottom: 15px;
		}
	}
}
.noticias__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(1, 1fr);
	}
}
.noticia__card{
	display: block;
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}
.noticia__card__imagem{
	margin-bottom: 12px;
}
.noticia__card__titulo{
	font-family: montserratbold;
	font-size: 16px;
	line-height: 20px;
	color: #1f1634;
	margin-bottom: 5px;
	min-height: 40px;
}
.noticia__card__desc{
	font-size: 14px;
	line-height: 18px;
	--linhas: 3;
	min-height: calc(18px * 3);
}
.noticia__header{
	max-width: 550px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	.titulo__area{
		margin-bottom: 36px;
	}
}
.noticia__card{
	position: relative;
	transition: all 0.3s linear;

	.noticia__card__titulo{
		transition: all 0.3s linear;
	}

	&:hover{
		text-decoration: none;
		.noticia__card__titulo{
			color: #ff5500;
		}
	}
}
.titulo__interna{
	font-family: montserratbold;
	font-size: 31px;
	line-height: 34px;
	text-align: center;
	max-width: 690px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
}
.links__desc{
	padding-top: 10px;
	max-width: 890px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	text-align: center;
}
.links__listagem{
	margin-bottom: 30px;
	a{
		display: flex;
		align-items: center;
		padding: 30px 50px;
		border: 1px solid #c7c7c7;
		border-bottom: none;
		justify-content: space-between;

		@include media-breakpoint-down (md) {
			padding: 20px 15px;
		}

		&:hover{
			text-decoration: none;

			.link__bolinha{
				background-color: #ff5500;
			}
		}

		.link__bolinha{
			width: 24px;
			height: 24px;
			background-color: #1f1634;
			border-radius: 50%;
			margin-right: 20px;
			transition: all 0.3s linear;
			min-width: 24px;
		}
		.link__texto{
			max-width: 920px;
		}
		&:last-child{
			border-bottom: 1px solid #c7c7c7;
		}
	}
}
.arquivos__header{
	font-family: montserratbold;
	font-size: 30px;
	line-height: 34px;
	color: #ff5500;
	display: grid;
	grid-template-columns: 65% 25% 10%;

	@include media-breakpoint-down (md){
		display: none;
	}
}
.arquivo__desc{
	padding: 20px 15px 20px 45px;
}
.arquivo__nome{
	padding: 20px 15px;
}
.arquivo__icone{
	background-color: #1f1634;
	color: #ff5500;
	transition: all 0.3s linear;
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon__mask{
		background-color: currentColor;
	}
}
.arquivo__link{
	display: grid;
	grid-template-columns: 65% 25% 10%;
	align-items: center;
	border: 1px solid #c7c7c7;
	border-bottom: none;

	@include media-breakpoint-down (md) {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: stretch;
		border-bottom: 1px solid #c7c7c7;
		margin-bottom: 20px;

		& > *{
			padding: 15px !important;
		}
	}

	&:hover{
		text-decoration: none;
		.arquivo__icone{
			background-color: #ff5500;
			color: #1f1634;
		}
	}

	&:last-child{
		border-bottom: 1px solid #c7c7c7;
	}
}
.arquivos__listagem{
	margin-bottom: 30px;
}
.info__listagem{
	margin-bottom: 30px;
}
.info__link{
	display: grid;
	grid-template-columns: 1fr 100px;
	align-items: center;
	border: 1px solid #c7c7c7;
	border-bottom: none;

	@include media-breakpoint-down (md) {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: stretch;
		border-bottom: 1px solid #c7c7c7;
		margin-bottom: 20px;

		& > *{
			padding: 15px !important;
		}
	}

	&:hover{
		text-decoration: none;
		.info__icone{
			background-color: #ff5500;
			color: #1f1634;
		}
	}
	.info__icone{
		background-color: #1f1634;
		color: #ff5500;
		transition: all 0.3s linear;
		width: 100%;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon__mask{
			background-color: currentColor;
		}
	}

	.info__desc{
		padding: 20px 45px 20px 45px;
	}

	&:last-child{
		border-bottom: 1px solid #c7c7c7;
	}
}
.modal__aviso{
	.modal-dialog{
		max-width: 720px;
		width: 100%;

		.modal-body{
			padding: 0px;
			max-width: 540px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
		.modal-header{
			display: flex;
			justify-content:flex-end;
			border: none;
		}
	}
	.titulo__interna{
		font-size: 30px;
		line-height: 34px;
	}
	.modal-content{
		padding-bottom: 40px;
	}
}
