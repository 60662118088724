.condominio__card__interna{
	display: block;
	padding: 40px 10px 20px 10px;
	background-color: #EEEEEE;
	color: #000;
	border: none;
	margin-bottom: 20px;

	&:focus{
		outline: none;
	}
}
.condominio__card__img{
	margin-bottom: 25px;
	text-align: center;
}
.condominio__titulo{
	font-family: 'montserratbold';
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 15px;
	text-align: center;
	min-height: 40px;

	.fas{
		margin-right: 5px;
	}
}
.condominio__endereco{
	text-align: center;
	font-size: 16px;
	line-height: 18px;
	min-height: calc(18px * 4);

	.fas{
		margin-right: 10px;
	}
}
