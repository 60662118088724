.banner__carousel{
	width: 100%;
	max-width: 1920px;
	position: relative;
	overflow: hidden;
	margin-right: auto;
	margin-left: auto;
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__prev,
.banner__next{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.banner__prev{
	left: 10%;
}
.banner__next{
	right: 10%;
}
.conecte__header{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 15px;
}
.conecte{
	padding-top: 90px;
	position: relative;
	color: #fff;
	overflow: hidden;

	&:after{
		width: 1920px;
		height: calc(100% - 160px);
		position: absolute;
		z-index: -1;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		background-image: url('../images/bg__conectese.jpg');
		content: '';
	}
}
.conecte__titulo{
	font-family: 'montserratbold';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 5px;
}
.conecte__sub{
	font-family: 'montserratregular';
	font-size: 50px;
	line-height: 54px;

	span{
		font-family: 'montserratbold';
	}
}
.conecte__desc{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 40px;
}
.row__conecte{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 50px;
}
.conecte__icone{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	margin-bottom: 10px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}
.conecte__item__titulo{
	font-family: 'montserratbold';
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 5px;
}
.conecte__card{
	max-width: 230px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.conecte__apps{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 50px;
}
.app__card{
	display: block;
	max-width: 206px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		text-decoration: none;
	}
}
.app__icone{
	width: 162px;
	height: 162px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0px 0px 20px rgba(#fff, 0.16);
	margin-bottom: 40px;
}
.app__titulo{
	font-family: 'montserratbold';
	font-size: 16px;
	line-height: 20px;
	text-align: center;
}
.row__apps{
	margin-bottom: 75px;
}
.app__img{
	.col-lg-5{
		display: flex;
		align-items: flex-end;
		padding-bottom: 170px;

		.conecte__sub{
			--linhas: 5;
		}
	}
}
.planos{
	position: relative;
	overflow: hidden;
	padding-bottom: 80px;
	padding-top: 20px;

	&:before{
		width: 1920px;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
		top: 0px;
		background-image: url('../images/bg__planos.png');
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: 1105px 1470px;
		position: absolute;
		content: '';
	}
}
.planos__header{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}
.titulo__planos{
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 40px;

	span{
		font-family: 'montserratbold';
		color: #ff5500;
	}
}
.planos__txt__titulo{
	color: #1f1634;
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 5px;

	span{
		font-family: 'montserratbold';
	}
}
.planos__txt__desc{
	color: #1f1634;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 24px;
}
.planos__card{
	display: flex;
	flex-direction: column;
	width: 189px;
	height: 187px;
	background-image: url('../images/bg__plano.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	padding-top: 48px;
	margin-right: auto;
	margin-left: auto;
	max-width: 100%;

	&:hover{
		text-decoration: none;
	}
}
.planos__numero{
	font-family: 'montserratbold';
	font-size: 40px;
	color: #fff;
	line-height: 40px;
	margin-bottom: 20px;
}
.planos__titulo{
	font-family: 'montserratbold';
	font-size: 30px;
	line-height: 30px;
	color: #1f1634;
}
@include media-breakpoint-down (md) {
	.conecte{
		&:after{
			height: 100%;
		}
	}
	.app__img{
		.col-lg-5{
			padding-bottom: 40px;

			.conecte__sub{
				--linhas: 30;
			}
		}
	}
	.app__card{
		margin-bottom: 60px;
	}
	.planos__img{
		text-align: center;
		padding-top: 50px;
	}
	.planos__txt__titulo,
	.planos__txt__desc{
		text-align: center;
	}
}
@include media-breakpoint-down (sm) {
	.conecte__card{
		margin-bottom: 30px;
	}
	.conecte__sub,
	.titulo__planos,
	.rodape__titulo,
	.planos__txt__titulo,
	.suporte__header{
		font-size: 34px;
		line-height: 38px;
	}
}
