.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	color: #fff;
}
.login__btn{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 56px;
	height: 56px;
	background-color: #ff5500;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s linear;

	&:hover{
		color: #1f1634;
	}

	.icon__mask{
		background-color: currentColor;
	}
}
.central__btn{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 193px;
	height: 56px;
	border-radius: 50px;
	background-color: #ff5500;
	transition: all 0.3s linear;
	margin-left: 15px;
	color: #fff;

	&:hover{
		background-color: #ff5500;
		color: #1f1634;
		text-decoration: none;
	}
}
.segura__central__btn,
.segura__login__btn{
	display: flex;
	align-items: center;
}
@include media-breakpoint-up (lg) {
	.topo__mbl{
		display: none;
	}
	.segura__login__btn{
		margin-left: 25px;
	}
	.topo__main{
		position: relative;

		.container{
			display: flex;
			justify-content: space-between;
			position: absolute;
			top: 0px;
			left: 50%;
			transform: translateX(-50%);
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
	.main__menu{
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		margin: 0px 60px;
		margin-right: 0px;
	}
	.menu__item{
		display: flex;

		&.menu__item--active{
			text-decoration: none;
			color: #ff5500;
		}
	}
	.menu__link{
		display: flex;
		justify-content: center;
		align-items: center;

		&:hover{
			text-decoration: none;
			color: #ff5500;
		}
	}
	.topo{
		&.topo__interna{
			.topo__main{
				background-color: #000;
				.container{
					position: relative;
					left: 0px;
					transform: translateX(0%);
				}
			}
		}
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		display: block;
		max-width: 250px;
	}
	.main__menu{
		margin: 0px 30px;
		margin-right: 10px;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		z-index: 1000;
		height: 100%;
		overflow-y: auto;
		top: 0px;
		transition: all 0.4s linear;
		background-color: #000;
		left: -250px;
		width: 250px;

		.container{
			padding: 0px;
		}

		&.topo__main--shown{
			left: 0px;
		}
	}
	.bg__menu{
		position: fixed;
		z-index: 999;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.5);
		animation: fadeIn 0.3s linear;

		&.hide{
			animation: fadeOut 0.3s linear;
		}
	}
	.topo__mbl{
		background-color: #000;
		padding: 20px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	.main__logo{
		display: block;
		padding: 10px;
		margin-bottom: 10px;
	}
	.menu__link{
		display: block;
		padding: 10px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #ff5500;
			color: #fff;
		}
	}
	.menu__item--active{
		.menu__link{
			background-color: #ff5500;
			color: #fff;
		}
	}
	.main__menu{
		margin-bottom: 20px;
	}
	.segura__central__btn{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mbl__botao{
		color: #fff;
		font-size: 30px;
		line-height: 30px;
		background-color: transparent;
		border: none;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 250px;
		margin-right: 15px;
	}
}
