.flytech__interna{
	overflow: hidden;
}
.suporte__header{
	text-align: center;
	font-size: 40px;
	line-height: 44px;
	max-width: 800px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 40px;

	@include media-breakpoint-down (sm) {
		font-size: 30px;
		line-height: 34px;
	}

	span{
		color: #ff5500;
		font-family: 'montserratbold';
	}

}
.suporte__card{
	text-align: center;
	max-width: 214px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.suporte__icone{
	margin-bottom: 15px;
}
.suporte__item__titulo{
	font-family: 'montserratbold';
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 5px;
}
.suporte__owl.owl-carousel{
	margin-bottom: 20px;

	.owl-nav{
		button.owl-prev,
		button.owl-next{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
@include media-breakpoint-up (xl) {
	.suporte__owl.owl-carousel{
		.owl-nav{
			button.owl-prev{
				left: -50px;
			}
			button.owl-next{
				right: -50px;
			}
		}
	}
}
@include media-breakpoint-only (lg) {
	.suporte__owl.owl-carousel{
		padding: 0px 30px;
		.owl-nav{
			button.owl-prev{
				left: -30px;
			}
			button.owl-next{
				right: -30px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.suporte__owl.owl-carousel{
	.owl-nav{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		padding-top: 20px;

		button.owl-prev,
		button.owl-next{
			position: relative;
			top: 0%;
			transform: translateY(0%);
			margin: 0px 10px;

			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
}
}
