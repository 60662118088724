.rodape__conteudo{
	background-color: #E7EEEF;
	padding: 40px 0px 55px 0px;
}
.rodape__titulo{
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 10px;

	span{
		font-family: 'montserratbold';
	}
}
.rodape__icone{
	width: 60px;
	min-width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background-color: #ff5500;
	font-size: 26px;
	line-height: 26px;
}
.rodape__box{
	display: flex;
	margin-bottom: 15px;
	align-items: center;
}
.contato__titulo{
	font-family: 'montserratbold';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 2px;
}
.rodape__desc{
	margin-bottom: 20px;
}
.rodape__input{
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 17px;
	color: #fff;
	height: auto;
	padding: 15px;
	background-color: #1f1634;
	display: block;
	width: 100%;
	border: none;

	&::placeholder{
		color: #fff;
	}
	&:focus{
		outline: none;
	}
}
.rodape__form{
	display: flex;
	margin-bottom: 20px;
}
.rodape__btn{
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 180px;
	background-color: #ff5500;
	color: #fff;
	font-family: 'montserratbold';
	border: none;
	padding: 10px;

	&:focus{
		outline: none;
	}
}
.rodape__sociais{
	display: flex;
	font-size: 20px;
	line-height: 20px;

	a{
		color: #1f1634;
		display: block;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			text-decoration: none;
			color: #ff5500;
		}
	}
}
.rodape__creditos{
	background-color: #ff5500;
	color: #fff;
	text-align: center;
	font-family: 'montserratregular';
	font-size: 12px;
	line-height: 16px;
	padding: 30px 0px;

	.container{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.gv8__box{
	display: flex;
	align-items: center;

	&:hover{
		text-decoration: none;
	}

	.icon{
		margin-left: 10px;
	}
}
@include media-breakpoint-down (md) {
	.rodape__sociais{
		justify-content: center;
		margin-bottom: 20px;
	}
	.rodape__creditos{
		.container{
			flex-direction: column;
			align-items: center;

			.barrinha{
				display: none;
			}
		}
	}
}
@include media-breakpoint-down (xs) {
	.rodape__form{
		flex-direction: column;
		align-items: center;
	}
	.rodape__box{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.rodape__icone{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
}

.btn__whatsapp{
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 25px;
	right: 25px;
	z-index: 900;
	opacity: 1;
	border-radius: 50%;
	transition: all 0.3s linear;
	box-shadow: 0px 0px 5px rgba(#000, 0.3);

	&:hover{
		box-shadow: 0px 0px 15px rgba(#000, 0.6);
	}
}
