.flytech__header{
	text-align: center;
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 20px;

	span{
		font-family: 'montserratbold';
		color: #ff5500;
	}
}
.flytech__interna{
	padding: 80px 0px 50px 0px;
}
.btn__segura__voltar{
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.btn__voltar{
	width: 193px;
	height: 56px;
	border-radius: 56px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	line-height: 14px;
	color: #111744;
	background-color: #ff5500;
	transition: all 0.3s linear;

	&:hover{
		color: #fff;
		text-decoration: none;
	}
}
.conteudo__gerenciavel{
	font-size: 13px;
	line-height: 17px;
}
