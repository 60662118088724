.titulo__contato{
	max-width: 590px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	font-size: 40px;
	line-height: 44px;
	margin-bottom: 5px;
}
.contato__desc{
	max-width: 860px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}
.form__contato{
	max-width: 940px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.form__planos__btn{
	display: flex;
	justify-content: center;
	width: 100%;
}
